import { getStorage } from '@/commons/SessionStorageUtils'
import { setRouterFullPath } from '@/commons/routerFullPathError'

import { ElMessage } from 'element-plus'
import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  useRouter
} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/invoice',
    name: 'Invoice',
    meta: {
      title: '发票页面'
    },
    component: () => import('@/views/Invoice.vue')
  },

  {
    path: '/invoicesuccess',
    name: 'Invoicesuccess',
    meta: {
      title: '发票成功页面'
    },
    component: () => import('@/views/Invoicesuccess.vue')
  },

  {
    path: '/order',
    name: 'Order',
    meta: {
      title: '订单页面'
    },
    component: () => import('@/views/Order.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      title: '支付页'
    },
    component: () => import('@/views/Pay.vue')
  },
  {
    path: '/alipay',
    name: 'AliPay',
    meta: {
      title: '支付页'
    },
    component: () => import('@/views/AliPay.vue')
  },
  {
    path: '/alipayment',
    name: 'AliPayment',
    meta: {
      title: '支付宝支付成功'
    },
    component: () => import('@/views/AliPayment.vue')
  },
  {
    path: '/paymentsuccess',
    name: 'PaymentSuccess',
    meta: {
      title: '微信支付成功'
    },
    component: () => import('@/views/PaymentSuccess.vue')
  },
  {
    path: '/electronic',
    name: 'electronic',
    redirect: '/documents-list',
    component: () => import('@/views/electronic.vue'),
    children: [
      {
        path: '/personal-center',
        name: 'PersonalCenter',
        meta: {
          title: '个人中心'
        },
        component: () => import('@/views/PersonalCenter.vue')
      },
      {
        path: '/documents-list',
        name: 'documents-list',
        meta: {
          title: '文书列表'
        },
        component: () => import('@/views/DocumentsList.vue')
      }
    ]
  },
  // {
  //   path: '/documents-list',
  //   name: 'documents-list',
  //   component: () => import('@/views/DocumentsList.vue')
  // },
  {
    path: '/confirmation',
    name: 'Confirmation',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/confirmation.vue')
  },
  // {
  //   path: '/personal-center',
  //   name: 'PersonalCenter',
  //   component: () => import('@/views/PersonalCenter.vue')
  // },
  {
    path: '/authentication',
    name: 'authentication',
    meta: {
      title: '实名认证'
    },
    component: () => import('@/views/authentication.vue')
  },
  {
    path: '/simple-signature',
    name: 'simple-signature',
    meta: {
      title: '手写签名'
    },
    component: () => import('@/views/simple-signature.vue')
  },
  {
    path: '/modify-password',
    name: 'ModifyPassword',
    meta: {
      title: '修改密码'
    },
    component: () => import('@/views/ModifyPassword.vue')
  },
  {
    path: '/sign-in-agreement',
    name: 'sign-in-agreement',
    meta: {
      title: '最高人民法院'
    },
    component: () => import('@/views/sign-in-agreement.vue')
  },
  {
    path: '/name-certification',
    name: 'NameCertification',
    meta: {
      title: '实名认证'
    },
    component: () => import('@/views/NameCertification.vue')
  },
  {
    path: '/personal-confirm-address',
    name: 'PersonalConfirmAddress',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/PersonalConfirmAddress.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: '告知事项'
    },
    component: () => import('@/views/notice.vue')
  },
  {
    path: '/documents-detail',
    name: 'documents-detail',
    meta: {
      title: '文书详情'
    },
    component: () => import('@/views/documents-detail.vue')
  },
  {
    path: '/confirmation-address',
    name: 'ConfirmAddressNormal',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/ConfirmAddressNormal.vue')
  },
  {
    path: '/kunshan-confirm',
    name: 'KunshanConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/KunshanConfirm.vue')
  },
  {
    path: '/pengJang-confirm',
    name: 'pengJangConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/pengJangConfirm.vue')
  },
  {
    path: '/confirmationOfAddress',
    name: 'ConfirmationOfAddress',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/ConfirmationOfAddress.vue')
  },
  {
    path: '/mianyangconfirmation',
    name: 'MianyangConfirmation',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/MianyangConfirmation.vue')
  },
  {
    path: '/confirmation-address-gx',
    name: 'ConfirmAddressGX',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/ConfirmAddressGX.vue')
  },
  {
    path: '/confirm-address-detail',
    name: 'ConfirmAddressDetail',
    meta: {
      title: '地址确认书详情'
    },
    component: () => import('@/views/ConfirmAddressDetail.vue')
  },
  {
    path: '/west-city-confirmation',
    name: 'WestCityConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/WestCityConfirm.vue')
  },
  {
    path: '/tian-qiao-confirm',
    name: 'tianQiaoConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/tianQiaoConfirm.vue')
  },
  {
    path: '/feng-xian-confirm',
    name: 'fengXianConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/fengXianConfirm.vue')
  },
  {
    path: '/long-gang-confirm',
    name: 'longGangConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/longGangConfirm.vue')
  },
  {
    path: '/li-cheng-confirm',
    name: 'liChengConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/LiChengConfirm.vue')
  },
  {
    path: '/wu-qing-confirm',
    name: 'wuQingConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/wuQingConfirm.vue')
  },
  {
    path: '/tian-he-confirm',
    name: 'tianHeConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/tianHeConfirm.vue')
  },
  {
    path: '/longhua-confirm',
    name: 'LonghuaConfirm',
    meta: {
      title: '地址确认书'
    },
    component: () => import('@/views/LonghuaConfirm.vue')
  },
  {
    path: '/img-pre-view',
    name: 'img-pre-view',
    component: () => import('@/components/preview/ImgPreView.vue'),
    meta: {
      title: '文书查看'
    }
  },
  {
    path: '/signature',
    name: 'signature',
    component: () => import('@/views/signature.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test.vue')
  },
  {
    path: '/qrCode',
    name: 'qrCode',
    meta: {
      title: '查看文书'
    },
    component: () => import('@/views/qrCode.vue')
  },
  {
    path: '/pdfPreview',
    name: 'pdfPreview',
    meta: {
      title: '文件预览'
    },
    component: () => import('@/views/pdfPreview.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

function getWriteRoutes() {
  return [
    '/',
    '/invoice',
    '/invoicesuccess',
    '/order',
    '/alipayment',
    '/alipay',
    '/pay',
    '/paymentsuccess',
    '/qrCode',
    '/test',
    '/tian-qiao-confirm',
    '/feng-xian-confirm',
    '/long-gang-confirm',
    '/li-cheng-confirm',
    '/wu-qing-confirm',
    '/tian-he-confirm',
    '/confirmation-address',
    '/kunshan-confirm',
    '/pengJang-confirm',
    '/confirmation-address-gx',
    '/west-city-confirmation',
    '/confirm-address-detail',
    '/img-pre-view',
    '/confirmationOfAddress',
    '/mianyangconfirmation',
    '/longhua-confirm',
    '/pdfPreview',
    '/confirmation',
    '/documents-detail'
  ]
}

// 添加导航钩子
router.beforeEach((to, _from, next) => {
  setRouterFullPath(to.fullPath)
  document.title = to.meta.title
  const writeRoutes = getWriteRoutes()
  if (_from.path == '/documents-detail') {
    if (_from.query.param == '1') {
      router.go(-1)
      return
    } else {
      next()
      return
    }
  }
  if (writeRoutes.indexOf(to.path) !== -1) {
    next()
    return
  }

  // 验证 是否有 token / key 都可以
  const token = getStorage('Token')
  if (!token) {
    // 提示
    ElMessage.warning({ type: 'warning', message: '请您重新登陆' })
    return next('/')
  }
  return next()
})

export default router
